import i18n from "i18n";
import { useEffect, useState } from "react";
import { $get } from "services";

// 站台資料

export interface SettingInfo {
  Id: number;
  SiteName: string;	          // 站台名稱
  Currency: string;           // 幣別
  Language: string | null;    // 語系
  Type: number;               // 0: 現金版 | 1: 信用版
  Status: number;             // 狀態
  Logo1: string;              // Favicon
  Logo2: string;              // 324px X  70px圖片path
  WelcomePagePC: string;      // 500px X 500px圖片path
  WelcomePageMobile: string;  // 325px X 325px圖片path
  WhiteList: string;          // 系統白名單
  CreateDate: string;         // 建立時間
  PhonePrefix: string;        // 預設國碼
  TimeZone: string;           // 時區
  Domain: string | null;      // 網域
  CommissionModule: string;   // 會員等級返水制
  CustomerServiceSetting: any; // 客服系統設定參數
  CustomerServiceModule: string; // 客服系統模組
}

const useSiteById = (siteId: any) => {
  // 站台資料
  const { data, mutate, isValidating } = $get({ url: `admin/site-data/sites/${siteId}/info`, allow: !!siteId });
  const [info, setInfo] = useState<SettingInfo>({
    Id: 0,
    SiteName: '',
    Currency: '',
    Language: null,
    Type: 0,
    Status: 0,
    Logo1: '',
    Logo2: '',
    WelcomePagePC: '',
    WelcomePageMobile: '',
    WhiteList: '',
    CreateDate: '',
    PhonePrefix: '',
    TimeZone: 'Asia/Taipei',
    Domain: null,
    CommissionModule: '2',
    CustomerServiceSetting: {
      entId: ''
    },
    CustomerServiceModule: '',
  });

  useEffect(() => {
    if (data?.Data) {
      const _info = Object.assign(JSON.parse(JSON.stringify(data.Data)), {
        Logo1: `${data.Data.Logo1}?${Date.now()}`,
        Logo2: `${data.Data.Logo2}?${Date.now()}`,
        WelcomePagePC: `${data.Data.WelcomePagePC}?${Date.now()}`,
        WelcomePageMobile: `${data.Data.WelcomePageMobile}?${Date.now()}`
      });

      setInfo(_info);
    }
  }, [data])

  window.serverZone = info?.TimeZone;
  
  return {
    data: info,
    mutate,
    isValidating,
    isCashVersion: data?.Data?.Type === 0,
    isOurService: data?.Data?.CustomerServiceModule === "0"
  }
};

export default useSiteById;
