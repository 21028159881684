import { Button, Checkbox, Col, Form, Input, Row, Select, Spin, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { CHECKBOX_DISPLAY, operationi18n, } from 'constants/permission';
import { ENABLE } from 'constants/state';
import { setTimestamp } from 'hooks/store.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { $api, $get } from 'services';
import { enumToOptions } from 'utils/common';

// 編輯權限

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const apiTimestamp = useSelector((state: any) => state.Timestamp);

  // 基本資料
  const { data: one } = $get({ url: `admin/permission/roles/${id}` });
  useEffect(() => {
    if (one?.Data) {
      form.setFieldsValue({
        name: one.Data.name,
        description: one.Data.description,
        isEnabled: one.Data.isEnabled ? ENABLE.enabled : ENABLE.disabled,
      })
    }
  }, [one]);

  // 權限列表
  const [allList, setAllList] = useState<any>([]);
  const path = `admin/permission/roles/${id}/authorities`;
  const { data: list } = $get({ url: path });
  // 聖經: 檢查api資料新舊
  // 如果資料是新的才建立tree
  useEffect(() => {
    if (list) {
      if (list.Timestamp && list.Timestamp !== apiTimestamp[path]) {
        const obj: any = {};
        obj[path] = list.Timestamp;
        dispatch(setTimestamp(obj));
        buildTree();
      }
    }
  }, [list]);

  // 建立權限tree物件
  const buildTree = () => {
    if (list) {
      const data: any = [];

      // 每個類別
      list.Data.forEach((category: any) => {
        // 每個頁面
        category.managements.forEach((page: any) => {
          data.push({
            ...page, key: page.code, rowSpan: 0,
            categoryId: category.id, categoryAlias: category.alias,
            pageId: page.code, pageAlias: page.alias,
            // 操作陣列
            child: page.operations.map((operation: any) => {
              return { operationId: operation.id, isSelected: operation.isSelected }
            })
          });
          // 當前類別的第一個頁面
          const theCategory = data.find((p: any) => p.categoryId === category.id);
          if (theCategory) theCategory.rowSpan++
        });
      });

      setAllList(data);
    }
  }

  useEffect(() => {
    updateCheckStateOne();
  }, [allList]);

  const [menuCheckState, setMenuCheckState] = useState<any>({});
  const updateCheckStateOne = async () => {
    const fieldsValue: any = {};

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    let categoryId = allList[0]?.categoryId;

    allList.forEach((page: any, i: number) => {
      managementSum = 0;
      menuTotal += page.child.length;

      page.child.forEach((operation: any) => {
        // 計算勾選數量
        if (operation.isSelected || operation.operationId === 'siteData.getSiteData') {
          managementSum++
          menuSum++
          fieldsValue[`cb-${operation.operationId}`] = true;
        }
      })
      if (managementSum === 0) menuCheckState[`management-${page.pageId}`] = CHECKBOX_DISPLAY.empty
      else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[`management-${page.pageId}`] = CHECKBOX_DISPLAY.square
      else if (managementSum === page.child.length) menuCheckState[`management-${page.pageId}`] = CHECKBOX_DISPLAY.tick

      // menu
      if ((allList[i + 1]?.categoryId !== categoryId) || !allList[i + 1]) {
        if (menuSum === 0) menuCheckState[`menu-${page.categoryId}`] = CHECKBOX_DISPLAY.empty
        else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[`menu-${page.categoryId}`] = CHECKBOX_DISPLAY.square
        else if (menuSum === menuTotal) menuCheckState[`menu-${page.categoryId}`] = CHECKBOX_DISPLAY.tick

        categoryId = allList[i + 1]?.categoryId;
        menuSum = 0;
        menuTotal = 0;
      }
    })
    form.setFieldsValue({ ...fieldsValue });
    setMenuCheckState({ ...menuCheckState });
    setLoading(false);
  };

  const onFinish = (formData: any) => {
    $api('PUT', {
      url: `admin/permission/roles/${id}`,
      send: {
        name: formData.name,
        description: formData.description,
        isEnabled: !!formData.isEnabled,
      },
      success: () => {
        updateAuthorities(formData);
      },
    }, setLoading)
  };

  const updateAuthorities = (formData: any) => {
    const operationIds: any = [];
    allList.forEach((page: any) => {
      page.child.forEach((operation: any) => {
        if (formData[`cb-${operation.operationId}`]) operationIds.push(operation.operationId);
      })
    })

    $api('PUT', {
      url: `admin/permission/roles/${id}/authorities`,
      send: {
        operationIds
      },
      success: () => {
        message.success(i18n.t('saveSuccess'));
        navigate('/advanced/permission');
      },
      fail: () => {
        if (operationIds.length === 0) {
          message.error(i18n.t('permissionsNotChecked'));
        }
      },
    }, setLoading)
  };

  const updateCheckState = async (record: any, level: any, checked: any) => {
    setLoading(true);

    const fieldsValue: any = {};

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    allList.forEach((page: any) => {
      managementSum = 0;

      if (page.categoryId === record.categoryId) {
        let readCount = false;
        menuTotal += page.child.length;

        page.child.forEach((operation: any, i: number) => {
          // 是邊勾邊算 不是勾完再算
          // 計算勾選數量
          if (
            (level === 'menu' && checked) ||
            (page.operationId === record.operationId && (
              (level === 'management' && checked) ||
              (level === 'operation' && form.getFieldValue(`cb-${operation.operationId}`))
            ))
          ) {
            managementSum++
            menuSum++
            if (i === 0) readCount = true;
          }
          else if (
            page.pageId !== record.pageId && (level === 'management' || level === 'operation') && form.getFieldValue(`cb-${operation.operationId}`)
          ) {
            menuSum++
          }
          // 改變勾選狀態
          if ((level === 'management' && page.pageId === record.pageId) || level === 'menu') {
            fieldsValue[`cb-${operation.operationId}`] = checked;
          }
          // 查看自動勾起
          if ((level === 'operation' && page.pageId === record.pageId) && checked) {
            if (page.child.length === 2 && !fieldsValue[`cb-${page.child[0].operationId}`] && !readCount) {
              managementSum++
              menuSum++
              readCount = true;
            }
            fieldsValue[`cb-${page.child[0].operationId}`] = true;
          }
        })

        if (((level === 'management' || level === 'operation') && page.pageId === record.pageId) || level === 'menu') {
          if (managementSum === 0) menuCheckState[`management-${page.pageId}`] = CHECKBOX_DISPLAY.empty
          else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[`management-${page.pageId}`] = CHECKBOX_DISPLAY.square
          else if (managementSum === page.child.length) menuCheckState[`management-${page.pageId}`] = CHECKBOX_DISPLAY.tick
        }
      }
    })

    if (menuSum === 0) menuCheckState[`menu-${record.categoryId}`] = CHECKBOX_DISPLAY.empty
    else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[`menu-${record.categoryId}`] = CHECKBOX_DISPLAY.square
    else if (menuSum === menuTotal) menuCheckState[`menu-${record.categoryId}`] = CHECKBOX_DISPLAY.tick
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })

    setLoading(false);
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1 pb-2">
        <Spin spinning={loading}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row>
              {/* 名稱 */}
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col span={20}>
                        <Form.Item name="name" label={i18n.t('permissionName')} rules={[{ required: true }]}>
                          <Input className="w-26" placeholder={`${i18n.t('inputData')}`} maxLength={100} showCount />
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item name="description" label={i18n.t('description')}>
                          <Input.TextArea className="w-26" placeholder={`${i18n.t('inputData')}`} autoSize={{ minRows: 2, maxRows: 1 }} />
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item name="isEnabled" className="w-26" label={i18n.t('status')} rules={[{ required: true }]}>
                          <Select
                            placeholder={`${i18n.t('pleaseSelect')}`}
                            options={enumToOptions(ENABLE)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Button className="mr-1" onClick={() => navigate('/advanced/permission')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                  </Col>
                </Row>
              </Col>
              {/* 權限 */}
              <Col className="size-16 font-w-md mt-1">{i18n.t('backOfficePermissions')}</Col>
              <Col span={24} className="mt-1">
                <Table
                  size="middle"
                  bordered
                  dataSource={allList}
                  columns={[
                    {
                      title: <><span className="require">*</span>{i18n.t('page')}</>,
                      width: '25%',
                      onCell: (record: any) => ({ rowSpan: record.rowSpan || 0 }),
                      render: (_, record: any) =>
                        <Checkbox
                          indeterminate={menuCheckState[`menu-${record.categoryId}`] === CHECKBOX_DISPLAY.square}
                          checked={menuCheckState[`menu-${record.categoryId}`] === CHECKBOX_DISPLAY.tick}
                          onChange={e => updateCheckState(record, 'menu', e.target.checked)}
                        >
                          {i18n.t(record.categoryAlias)}
                        </Checkbox>,
                    },
                    {
                      className: 'size-12',
                      width: '25%',
                      render: (_, record: any) =>
                        <Checkbox
                          indeterminate={menuCheckState[`management-${record.pageId}`] === CHECKBOX_DISPLAY.square}
                          checked={menuCheckState[`management-${record.pageId}`] === CHECKBOX_DISPLAY.tick}
                          onChange={e => updateCheckState(record, 'management', e.target.checked)}
                        >
                          {record.pageAlias.split(',').map((s: any) => i18n.t(s)).join('-')}
                        </Checkbox>
                    },
                    {
                      title: i18n.t('permissions'),
                      width: '50%',
                      render: (_, record) => record.child.map((item: any) =>
                        <Form.Item key={item.operationId} name={`cb-${item.operationId}`} valuePropName="checked" style={{ display: 'inline-block' }}>
                          <Checkbox
                            // 首頁特規
                            disabled={item.operationId === 'siteData.getSiteData'}
                            onChange={e => updateCheckState(record, 'operation', e.target.checked)}
                          >
                            {operationi18n[item.operationId] || item.operationId}
                          </Checkbox>
                        </Form.Item>
                      )
                    },
                  ]}
                  pagination={false}
                />
              </Col>
            </Row>
          </Form>
        </Spin>
      </Content>
    </div>
  );
};

export default PageMain;