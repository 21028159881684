import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select, Tabs, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, DatePickerUnix } from 'components/layout.component';
import { GameCategorySelect } from 'components/member.component';
import { BettingReportMemberTable, PopupBetInfo } from 'components/report.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'constants/date';
import { REPORT_STATE } from 'constants/state';
import i18n from 'i18n';
import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import { cleanCommaString as $c, removeDuplicates as $rd, enumToOptions, selectFuzzyFilter, specialProviderName, timeL2S } from "utils/common";
import { RESPONSE_CODE } from "constants/response";
import { SiteCodeAndName } from "../components/site.component";
import useSiteById from "hooks/site-id.hook";

// 投注報表

const PageMain: React.FC = () => {
  const { account } = useParams();

  const [form] = Form.useForm();
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
  const [reset, setReset] = useState<boolean>(false);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [betId, setBetId] = useState<number | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [accountList, setAccountList] = useState<any>(['']);
  const [activeKey, setActiveKey] = useState('');
  const [searchAccount, setSearchAccount] = useState<any>('');
  const [oldSearchAccount, setOldSearchAccount] = useState<any>('');
  const [siteId, setSiteId] = useState<number | null>();
  const { data: $s } = useSiteById(siteId);

  const refList0 = useRef<any>();
  const refList1 = useRef<any>();
  const refList2 = useRef<any>();
  const refList3 = useRef<any>();
  const refList4 = useRef<any>();
  const refList5 = useRef<any>();
  const refList6 = useRef<any>();
  const refList7 = useRef<any>();
  const refList8 = useRef<any>();
  const refList9 = useRef<any>();
  const [betSearchParam, setBetSearchParam] = useState<any>({
    memberAccount: account || '',
    endTimeStart: timeL2S(date[0]),
    endTimeEnd: timeL2S(date[1], 999)
  });
  useEffect(() => {
    upDateParams()
  }, []);

  // 檢查會員是否存在
  const { data: memberCheck, mutate } = $get({
    url: `admin/betticket/sites/${siteId}/members/check`,
    params: {
      memberAccounts: $c(searchAccount)
    },
    success: () => {
      upDateParams()
    },
    fail: (response: any) => {
      // 錯誤訊息
      const errorData = response?.memberAccounts;
      message.error(<>
        {errorData
          ? <>
            {i18n.t('notFoundAccount')}
            <Row>
              {errorData.map((account: string, i: number) => <Col span={24} key={i}>{account}</Col>)}
            </Row>
          </>
          : RESPONSE_CODE[response.errorCode as keyof typeof RESPONSE_CODE]
        }
      </>);
    },
    showMessage: false,
    allow: !!searchAccount
  });

  // 搜尋
  const search = (formData: any) => {
    // 如果不搜尋帳號 直接更新參數
    if (formData.MemberAccount === '') {
      setSearchAccount('');
      setOldSearchAccount('');
      upDateParams();
      listMutate();

    } else {
      // 不能超過10個會員帳號
      const _accountList = $rd($c(formData.MemberAccount).split(','));
      if (_accountList.length > 10) message.error(i18n.t('moreThan10Members'));

      else {
        // 如果帳號沒變就 mutate
        if ($c(formData.MemberAccount) === oldSearchAccount) {
          if (memberCheck && memberCheck.State === "Success") {
            upDateParams();
            listMutate();
          }
          else mutate();

          // 重新檢查
        } else {
          setSearchAccount($c(formData.MemberAccount));
          setOldSearchAccount($c(formData.MemberAccount));
        }
      }
    }
  };

  const siteCodeChange = (id: number) => {
    onClear();
    form.setFieldsValue({ siteId: id });
    setTimeout(() => {
      setSiteId(id);
    });
  }

  // setTimeout跟useEffect都要用上才能拉出date重置的延遲
  useEffect(() => {
    upDateParams();
  }, [siteId]);

  // 數據更新等同 call get list
  const upDateParams = () => {
    const formData = form.getFieldsValue()

    const _accountList = $rd($c(formData.MemberAccount).split(','));
    setActiveKey(_accountList[0]);
    setAccountList(_accountList);
    // 準備參數
    const param: any = {
      roundId: formData.RoundId,
      memberAccount: $c(formData.MemberAccount),
      agentAccount: formData.AgentAccount,
      status: formData.Status,
      isPromo: formData.providerPromotions
    };

    if (formData.ProviderCode !== 0) {
      param.providerCode = formData.ProviderCode;
    }

    if (formData.CategoryCode !== 0) {
      param.categoryCode = formData.CategoryCode;
    }

    if (formData.Status === REPORT_STATE.notSettle || formData.Status === REPORT_STATE.unsettledCancellation) {
      param.betTimeStart = timeL2S(date[0])
      param.betTimeEnd = timeL2S(date[1], 999)
    } else {
      switch (formData.TimeMode) {
        case 0:
          param.endTimeStart = timeL2S(date[0])
          param.endTimeEnd = timeL2S(date[1], 999)
          break;
        case 1:
          param.betTimeStart = timeL2S(date[0])
          param.betTimeEnd = timeL2S(date[1], 999)
          break;
      }
    }
    setBetSearchParam(param);
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setSiteId(null);
  }

  const listMutate = () => {
    refList0.current?.mutate();
    refList1.current?.mutate();
    refList2.current?.mutate();
    refList3.current?.mutate();
    refList4.current?.mutate();
    refList5.current?.mutate();
    refList6.current?.mutate();
    refList7.current?.mutate();
    refList8.current?.mutate();
    refList9.current?.mutate();
  }

  const onStatusChange = (status: number) => {
    if (status === REPORT_STATE.notSettle || status === REPORT_STATE.unsettledCancellation) {
      setIsDisabled(true);
      form.setFieldValue('TimeMode', 1);
    } else {
      setIsDisabled(false);
    }
  }

  // 開啟詳情
  const handleBetInfo = (betId: number) => {
    setIsOpenInfoModal(true);
    setBetId(betId);
  }

  // 取得遊戲商
  const { data: provider } = $get({ url: `admin/betticket/sites/${siteId}/provider/codes`, allow: !!siteId });

  // 當輸入","會自動換行
  const handleFormChange = (changedValues: any, allValues: any) => {
    const memberAccount = allValues.MemberAccount || '';
    if (memberAccount.endsWith(',')) {
      const newMemberAccount = memberAccount + '\n';
      form.setFieldsValue({
        MemberAccount: newMemberAccount
      });
    }
  };

  // 最多只能輸入10行
  const handleTextAreaChange = (e: any) => {
    const value = e.target.value;
    const lines = value.split('\n');
    if (lines.length > 10) {
      const truncatedValue = lines.slice(0, 10).join('\n');
      form.setFieldsValue({
        MemberAccount: truncatedValue
      });
    }
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋 */}
        <Form
          form={form}
          onFinish={search}
          // onValuesChange={handleFormChange}
          initialValues={{
            TimeMode: 0,
            MemberAccount: account || '',
          }}
        >
          <Row align="top" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="siteId" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}${i18n.t('platform')}` }]}>
                <SiteCodeAndName name="siteId" form={form} apiUrl={'admin/betticket/sites'} handleChangeFunction={siteCodeChange} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="RoundId">
                <Input disabled={!siteId} placeholder={`${i18n.t('roundNumber')}`}/>
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="MemberAccount">
                <Input.TextArea
                  disabled={!siteId}
                  placeholder={`${i18n.t('memberAccount')}${i18n.t('pleaseUseCommaToSeparateTheItems')}`}
                  autoSize={{ minRows: 1, maxRows: 2 }}
                  // onChange={handleTextAreaChange}
                />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="AgentAccount">
                <Input disabled={!siteId} placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="ProviderCode">
                <Select
                  disabled={!siteId}
                  showSearch
                  filterOption={selectFuzzyFilter}
                  placeholder={i18n.t('gameProvider')}
                  options={
                    provider &&
                    [{ value: '', label: i18n.t('ALL') },
                      ...provider?.Data.map((item: any) => ({
                        value: item.providerCode,
                        label: specialProviderName(item.providerName, $s.SiteName, item.providerCode)
                      }))]
                  }
                />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="CategoryCode">
                <GameCategorySelect name="CategoryCode" form={form} code disabled={!siteId} siteId={siteId}/>
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="providerPromotions">
                <Select
                  disabled={!siteId}
                  placeholder={i18n.t('type')}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    { value: 0, label: i18n.t('bettingSlip') },
                    { value: 1, label: i18n.t('providerPromotions') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" align="middle" gutter={[12, 12]}>
            <Col className='w-12'>
              <Form.Item name="Status">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t('status')}`}
                  onChange={onStatusChange}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    ...enumToOptions(REPORT_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="TimeMode">
                <Select
                  style={{ width: '100%' }}
                  options={[
                    { value: 0, label: i18n.t('settlementTime'), disabled: isDisabled },
                    { value: 1, label: i18n.t('betTime') },
                  ]}
                />
              </Form.Item>
            </Col>
            <DatePickerUnix width={180} date={date} setDate={setDate} initDate={reset}
              ignoreDateType={[DATE_TYPE.thisMonth, DATE_TYPE.lastMonth]} rangeLimit={DATE_RANGE_LIMIT.past7Days} />
            <Col>
              <Tooltip placement="top" className="size-12" title={i18n.t('singleQueryInterval7Days')}>
                <InfoCircleFilled />
              </Tooltip>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>

        {/* 帳號頁籤 */}
        {searchAccount &&
          <Tabs className="mt-1" activeKey={activeKey} onTabClick={setActiveKey} items={accountList.map((account: any) => ({
            key: account,
            label: <div className="w-5 text-center">{account}</div>
          }))} />
        }

        <BettingReportMemberTable i={99} memberParams={betSearchParam} activeKey={activeKey} account={accountList[0]} handleBetInfo={handleBetInfo} refs={refList0} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[1]} handleBetInfo={handleBetInfo} refs={refList1} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[2]} handleBetInfo={handleBetInfo} refs={refList2} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[3]} handleBetInfo={handleBetInfo} refs={refList3} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[4]} handleBetInfo={handleBetInfo} refs={refList4} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[5]} handleBetInfo={handleBetInfo} refs={refList5} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[6]} handleBetInfo={handleBetInfo} refs={refList6} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[7]} handleBetInfo={handleBetInfo} refs={refList7} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[8]} handleBetInfo={handleBetInfo} refs={refList8} siteId={siteId}/>
        <BettingReportMemberTable memberParams={betSearchParam} activeKey={activeKey} account={accountList[9]} handleBetInfo={handleBetInfo} refs={refList9} siteId={siteId}/>

        {/* 詳情 */}
        <PopupBetInfo isOpen={isOpenInfoModal} close={() => setIsOpenInfoModal(false)} betId={betId} siteId={siteId}/>
      </Content>
    </div >
  );
};

export default PageMain;