import i18n from "i18n"

// 權限列表 翻譯

export enum CHECKBOX_DISPLAY {
  empty = 0,
  square = 1,
  tick = 2
}

// 權限管理: 操作
export const operationi18n: any = {

  //會員
  "member.getMemberList": i18n.t('view'),
  "member.exportMemberList": i18n.t('export'),

  "memberInformation.getMember": i18n.t('view'),
  "memberInformation.getMemberMemoList": `${i18n.t('remark')}-${i18n.t('view')}`,

  "memberProviderSetting.getMemberProviderSettingList": i18n.t('view'),
  "memberContact.getMemberContactList": i18n.t('view'),
  "memberWithdrawMethohdList.getMemberWithdrawMethodList": i18n.t('view'),
  "memberOperatParams.getMemberOperatParams": i18n.t('view'),
  "accountDetail.getMemberAccountDetail": i18n.t('view'),

  // 統計報表
  "betRecord.getBetRecord": i18n.t('view'),
  "betRecord.exportBetRecord": i18n.t('export'),

  "providerReceivable.getReport": i18n.t('view'),
  "providerReceivable.exportData": i18n.t('export'),
  "providerReceivable.modifySetting": i18n.t('edit'),

  "smsLog.getLogs": i18n.t('view'),
  "smsLog.getTemplate": i18n.t('setting'),

  // 進階
  "siteData.getSiteData": i18n.t('view'),
  "siteData.modifySiteData": i18n.t('edit'),

  // 遊戲資料
  "gameData.getProviderListByCC": i18n.t('view'),
  "gameData.updateProviderOpen": i18n.t('edit'),

  "providerSort.getProviderSortList": i18n.t('view'),
  "providerSort.updateProviderPosition": i18n.t('edit'),

  "gameList.getGameList": i18n.t('view'),
  "gameList.updateGame": i18n.t('edit'),

  "providerCommission.getProviderCommissions": i18n.t('view'),
  "providerCommission.updateProviderCommission": i18n.t('edit'),

  // 上傳牌照
  "license.getLicenses": i18n.t('view'),
  "license.createLicense": i18n.t('add'),
  "license.updateLicense": i18n.t('edit'),
  "license.removeLicense": i18n.t('delete'),

  "customerServiceSoftware.getCustomerServiceSoftwareSettings": i18n.t('view'),
  "customerServiceSoftware.updateCustomerServiceSoftwareSettings": i18n.t('edit'),

  "permissionRole.getRoles": i18n.t('view'),
  "permissionRole.addRole": i18n.t('add'),
  "permissionRole.modifyRole": i18n.t('edit'),

  "permissionUser.getUsers": i18n.t('view'),
  "permissionUser.addUser": i18n.t('add'),
  "permissionUser.modifyUser": i18n.t('edit'),
  "permissionUser.modifyPassword": i18n.t('modifyPassword'),

  // 系統公告
  "systemAnnouncement.getSystemAnnouncements": i18n.t('view'),
  "systemAnnouncement.addSystemAnnouncement": i18n.t('add'),
  "systemAnnouncement.modifySystemAnnouncement": i18n.t('edit'),
  "systemAnnouncement.removeSystemAnnouncement": i18n.t('delete'),

  // 遊戲管理
  "game.getGames": i18n.t('view'),
  "game.modifyGame": i18n.t('edit'),

  "game.getCCGames": i18n.t('view'),
  "game.modifyCCGame": i18n.t('edit'),

  "adminUserLoginLog.getAdminUserLoginLogs": i18n.t('view'),
}
