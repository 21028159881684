import { Button, Col, Image, Row, Spin, Switch, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { LayoutTabMember } from 'components/member.component';
import React from 'react';
import { $get } from 'services';
import { useParams } from 'react-router-dom';
import i18n from 'i18n';
import { CONTACT_INFO, SOURCE_ROLE } from 'constants/state';

// 會員列表: 聯絡資訊

const PageMain: React.FC = () => {
  const { id, platformId, account, agId } = useParams();

  const { data, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/contacts`,
    allow: !!id
  });

  return (
    <div id="container">
    <LayoutNav />
    <Content>
      <LayoutTabMember activeKey="3" id={id} platformId={platformId} account={account} agId={agId}/>
        <Row>
          <Col span={24}>
            <Spin spinning={isValidating}>
              <Table
                className="mt-1"
                size="small"
                dataSource={data?.Data.map((item: any) => ({ ...item, key: item.Id }))}
                columns={[
                  {
                    dataIndex: 'SourceRole',
                    title: i18n.t('source'),
                    width: 150,
                    render: (val) => i18n.t(SOURCE_ROLE[val])
                  },
                  {
                    dataIndex: 'SourceAccount',
                    title: i18n.t('account'),
                    width: 200,
                  },
                  {
                    dataIndex: 'Status',
                    title: i18n.t('status'),
                    width: 150,
                    render: (_: any, record: any) => (
                      <Switch
                        checked={!!record.Status}
                        disabled
                      />
                    )
                  },
                  {
                    dataIndex: 'ContactInfo',
                    title: i18n.t('contactType'),
                    width: 150,
                    render: (val) => i18n.t(CONTACT_INFO[val])
                  },
                  {
                    dataIndex: 'ContactContent',
                    title: i18n.t('content'),
                  },
                  {
                    dataIndex: 'Photo',
                    title: i18n.t('image'),
                    width: 320,
                    render: (val) => val && <Image src={val} height={40} />
                  },
                  {
                    title: i18n.t('operation'),
                    width: 150,
                    render: (_: any, record: any) => (
                      <>
                          <Button className="size-12" type="link" disabled>
                            {i18n.t('edit')}
                          </Button>

                          <Button className="size-12" type="link" disabled>
                            {i18n.t('delete')}
                          </Button>
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;