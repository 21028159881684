import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { Link, useRouteError } from "react-router-dom";
import i18n from 'i18n';

import PageMember from "pages/member.page";
import PageMemberBank from "pages/member-bank.page";
import PageMemberContact from "pages/member-contact.page";
import PageMemberInfo from "pages/member-info.page";
import PageMemberProvider from "pages/member-provider.page";
import PageMemberRebate from "pages/memeber-rebate.page";
import PageMemberAccount from "pages/account-records.page"

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const memberModule = [
  {
    path: "/", ErrorBoundary,
    children: [
      { path: '/member', Component: PageMember, Breadcrumb: [
        { title: i18n.t('member') },
        { title: i18n.t('memberList') },
      ]},
      { path: '/member/info/:platformId/:id/:account/:agId', Component: PageMemberInfo, Breadcrumb: [
        { title: i18n.t('member') },
        { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
        { title: ':account' }
      ]},
      { path: '/member/provider/:platformId/:id/:account/:agId', Component: PageMemberProvider, Breadcrumb: [
        { title: i18n.t('member') },
        { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
        { title: ':account' }
      ]},
      { path: '/member/contact/:platformId/:id/:account/:agId', Component: PageMemberContact, Breadcrumb: [
        { title: i18n.t('member') },
        { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
        { title: ':account' }
      ]},
      { path: '/member/bank/:platformId/:id/:account/:agId', Component: PageMemberBank, Breadcrumb: [
        { title: i18n.t('member') },
        { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
        { title: ':account' }
      ]},
      { path: '/member/rebate/:platformId/:id/:account/:agId', Component: PageMemberRebate, Breadcrumb: [
        { title: i18n.t('member') },
        { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
        { title: ':account' }
      ]},
      { path: '/member/account', Component: PageMemberAccount, Breadcrumb: [
          { title: i18n.t('member') },
          { title: i18n.t('accountRecords') }
        ]},
      { path: '/member/account/:platformId/:account', Component: PageMemberAccount, Breadcrumb: [
          { title: i18n.t('member') },
          { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
          { title: i18n.t('accountRecords') }
        ]}
    ],
  },
]