import { Button, Form, Input, message } from "antd";
import { LayoutNav, ModalLayout } from "components/layout.component";
import i18n from "i18n";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { $api } from "services";

// 修改密碼

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);

  const onSubmit = (formData: any) => {
    if (formData.NewPassword !== formData.ConfirmNewPassword) {
      message.error(i18n.t('passwordConfirmationError'));
      return
    }

    $api('PATCH', {
      // 修改自己密碼
      url: 'admin/permission/self/password',
      send: {
        password: formData.Password,
        newPassword: formData.NewPassword,
        confirmNewPassword: formData.ConfirmNewPassword,
      },
      success: () => {
        message.success(i18n.t('modifySuccess'));
        navigate('/');
      },
    }, isLoading)
    isLoading(true)
  };

  const validator = async (record: any, value: any) => {
    const regex = new RegExp('[a-zA-Z0-9*_-]{4,20}$');
    if (!regex.test(value)) return Promise.reject(i18n.t('incorrectPasswordFormat'));
    else return Promise.resolve();
  }

  return (
    <>
      <LayoutNav hidden />
      <ModalLayout paddingBottom="100px">
        <Form size="middle" onFinish={onSubmit}>
          <Form.Item className="login-title" style={{ padding: '10px 0', textAlign: 'center', color: '#666' }}>
            <p>{i18n.t('modifyPassword')}</p>
          </Form.Item>
          <Form.Item
            name="Password"
            rules={[
              { required: true, message: `${i18n.t('required')}` }, { validator }
            ]}
          >
            <Input.Password placeholder={`${i18n.t('currentPassword')}`} />
          </Form.Item>
          <Form.Item
            className="mt-1"
            name="NewPassword"
            rules={[
              { required: true, message: `${i18n.t('required')}` }, { validator }
            ]}
          >
            <Input.Password placeholder={`${i18n.t('newPassword')}`} />
          </Form.Item>
          <Form.Item
            className="mt-1"
            name="ConfirmNewPassword"
            rules={[
              { required: true, message: `${i18n.t('required')}` }, { validator }
            ]}
          >
            <Input.Password placeholder={`${i18n.t('confirmNewPassword')}`} />
          </Form.Item>
          <Form.Item className="center mt-1 login-btn-wrap" name="modify-password">
            <Button
              htmlType="submit"
              loading={loading}
              className="w-full login-btn"
              style={{ background: '#DABF8D' }}
              size="large"
            >
              {i18n.t('confirm')}
            </Button>
          </Form.Item>
        </Form>
      </ModalLayout>
    </>
  );
};

export default PageMain;
